import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import Stripe from "../payment/Stripe";
import { toast } from "react-toastify";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import Alert from "react-bootstrap/Alert";
import AlertHeading from "react-bootstrap/AlertHeading";
import CurrencytoWords from "../../utils/currencyNumberToWords";

function RequestQuoteModal({
  showModal,
  close,
  t,
  fields,
  formValues,
  setshowQuoteModal,
  setrequestQuote,
  paymentGateways,
  quotes,
  requestQuoteStatus,
  quotesData,
  checkQuotesSlotApi,
}) {
  const {
    register,
    setValue,
    setError,
    trigger,
    watch,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const steps = ["step1", "step2", "step3"];
  const [activeStep, setActiveStep] = useState(1);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
  const requestQuote = ApiHook.CallquoteRequest();
  const purchaseQuote = ApiHook.CallPurchaseQuote();
  const [alert, setalert] = useState(false);
  const [selectedPayment, setselectedPayment] = useState("");
  const [stripePaymentDetails, setstripePaymentDetails] = useState({
    publicKey: "",
    clientSecretKey: "",
  });

  const user = useSelector((state) => state?.dashboard?.appLayout?.user);

  console.log("===user====", user);
  const [vehicleId, setvehicleId] = useState();
  const { refetch: refetchQuoteDetailsTable } = ApiHook.CallQuoteDetailsTable(
    1,
    10,
    "",
    ""
  );
  const currency = useSelector((state) => state.user?.selectedCurrency);
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const formInputs = watch();
  const changeHandler = async (e, type) => {
    try {
      if (type === "vin_numb") {
        const vin = e.target.value;
        const response = await axios.get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=json`
        );
        console.log("==response=====", response.data);
        const results = response.data.Results;
        const year = results.find(
          (item) => item.Variable === "Model Year"
        )?.Value;
        const model = results.find((item) => item.Variable === "Model")?.Value;
        setValue("vehicle_model", model, { shouldValidate: true });
        setValue("vehicle_year", year, { shouldValidate: true });
        setVehicleInfo({ year, model });
      }
    } catch (error) {
      console.log("===errorvin=====", error);
    }
  };
  const handlePaymentTabClick = (tabId, title) => {
    setActiveTab(tabId);
    setselectedPayment(title);
    setValue("paymentType", tabId, { shouldValidate: true });
    if (title === "stripe") {
      const publicKey = paymentGateways?.find((obj) => obj.slug === "stripe")
        .PaymentGatewayDetail?.publicKey;
      const stripeMetaData = {
        username: user?.username,
        email: user?.email,
        quote: quotes?.find((obj) => obj.id === formInputs?.quoteType).name,
      };

      const user_data = {
        username: user?.username,
        email: user?.email,
        id: user.id,
      };
      const body = {
        type: "request_quote",
        productId: formInputs?.quoteType,
        totalAmount: formInputs?.totalAmount,
        metadata: stripeMetaData,
        user: user_data,
      };
      stripeIntent.mutateAsync(body, {
        onSuccess: (res) => {
          if (res.status) {
            setstripePaymentDetails({
              ...stripePaymentDetails,
              publicKey,
              clientSecretKey: res.client_secret,
            });
          } else {
            toast.error(res.description);
          }
        },
      });
    }
  };

  const handleRequestquote = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      data["email"] = formValues.email;
      const response = await requestQuote.mutateAsync(data);
      console.log("======response===ddf===", response);
      if (response.status) {
        setvehicleId(response?.data);
        setrequestQuote(true);
        setalert(true);
      } else {
        setshowQuoteModal(false);
        toast.error(t(response?.description || "quote_request_failed"));
      }
    }
  };

  const nextHandler = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveStep(activeStep + 1);

      // Automatically select Stripe on step 3
      if (activeStep === 2) {
        const stripeTab = paymentGateways.find(
          (gateway) => gateway.slug === "stripe"
        );
        if (stripeTab) {
          handlePaymentTabClick(stripeTab.id, stripeTab.slug);
        }
      }
    }
  };

  const previousHandler = () => {
    setActiveStep(activeStep - 1);
  };

  const selectQuoteType = (id, price) => {
    setValue("quoteType", id);
    setValue("quotePrice", price);
    setValue("totalAmount", price);
    if (formInputs.quotePartialAmountStatus) {
      const amount =
        (parseFloat(formInputs.quotePrice) * quotesData.minRegPay) / 100;
      setValue("totalAmount", `${amount}`);
    }
  };

  const submitHandler = async (
    paymentId = null,
    paymentIntent,
    paymentType
  ) => {
    const body = {
      paymentResponse: paymentIntent,
      paymentMethodslug: selectedPayment,
      totalAmount: formInputs.totalAmount,
      quoteType: formInputs.quoteType,
      vehicleId,
    };
    const response = await purchaseQuote.mutateAsync(body);
    if (response.status) {
      setshowQuoteModal(false);
      await refetchQuoteDetailsTable();
      setalert(false);
      setrequestQuote(false);
      reset();
      setstripePaymentDetails({
        ...stripePaymentDetails,
        publicKey: "",
        clientSecretKey: "",
      });
      setActiveStep(1);
      await checkQuotesSlotApi.refetch();
    }
  };

  const partialAmntCheckboxHandler = (e) => {
    const { checked } = e.target;
    let amount;
    if (checked) {
      setValue("quotePartialAmountStatus", checked);
      amount = (parseFloat(formInputs.quotePrice) * quotesData.minRegPay) / 100;
      setValue("totalAmount", amount);
    } else {
      amount = quotes?.find((obj) => obj.id === formInputs?.quoteType).price;
      setValue("totalAmount", amount);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={close}
      className="quote-modal"
      dialogClassName="custom-modal-dialog"
      backdrop="static"
      centered
    >
      <Modal.Header>{t("quote_request")}</Modal.Header>
      <Modal.Body style={{ padding: "30px" }}>
        <div className="row align-items-center request-quote-modal">
          <div
            className="main-content-regsiter"
            style={{ background: "none", boxShadow: "none" }}
          >
            <div className="wizard-form py-4 my-2">
              <ul id="progressBar" className="progressbar px-lg-5 px-0">
                {steps.map((step, index) => (
                  <li
                    key={`step-${index + 1}`}
                    id={`progressList-${index + 1}`}
                    className={`d-inline-block w-25 position-relative text-center float-start progressbar-list ${
                      index <= activeStep - 1 ? "active" : ""
                    }`}
                  >
                    {step}
                  </li>
                ))}
              </ul>
            </div>
            <div className="row">
              {alert && activeStep == 1 && (
                <Alert
                  variant="success"
                  onClose={() => setalert(false)}
                  dismissible
                >
                  <span>{t("quote_request_info")}</span>
                </Alert>
              )}
              {activeStep === 1 &&
                fields &&
                fields?.map((item, index) => {
                  return (
                    <div className="col-md-6" key={index}>
                      <div
                        className="modal_change_password_right_cnt"
                        style={{ padding: "0" }}
                      >
                        <div className="form-group mb-2">
                          <Form.Label>{t(item.code)}</Form.Label>
                          {item.type === "text" ? (
                            <Form.Group>
                              <Form.Control
                                id={item.code}
                                type="text"
                                disabled={!item.editable}
                                // placeholder={item.code === "driver_license_number" ? "DL-1420110012345" : item.code === "vin_numb" ? "1HGCM82633A123456" : ''}
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                  pattern:
                                    item.code === "vin_numb"
                                      ? {
                                          value: /^[A-HJ-NPR-Z0-9]{17}$/,
                                          message: t("invalid_vin_number"),
                                        }
                                      : undefined,
                                })}
                                onChangeCapture={(e) =>
                                  changeHandler(e, item.code)
                                }
                                isInvalid={!!errors[item.code]}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors[item.code]?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : item.type === "dropdown" ? (
                            <Form.Group>
                              <Form.Select
                                {...register(item.code, {
                                  required: {
                                    value: item.required,
                                    message: t("this_field_is_required"),
                                  },
                                })}
                                aria-label="Default select example"
                                isInvalid={!!errors[item.code]}
                              >
                                <option value="">Select</option>
                                {item?.options?.map((option, idx) => (
                                  <option value={option.id} key={idx}>
                                    {option.value}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors[item.code]?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {activeStep === 2 && (
              <div className="rqst-quote-types_container">
                <div className="quotes-wrapper">
                  {quotes.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`quote-card ${
                          item.id === formInputs.quoteType
                            ? "quote-card-active"
                            : ""
                        }`}
                        {...register("quoteType", {
                          required: { value: true },
                        })}
                        onClick={() => selectQuoteType(item.id, item.price)}
                      >
                        
                        <div className="quote-card-content">
                          <span className="quote-name">{item.name}</span>
                          <div className="quote-details">
                          <span className="quote-coverage">
                            Coverage Level: <br></br>
                           <span className="max_coverage_per_incident">{item.max_coverage_per_incident}</span> 
                          </span>
                          <span className="quote-price">
                            6 mo Cost: <br></br> <span className="quote-price-ka">{currency?.symbolLeft}
                            {CurrencyConverter(item?.price, conversionFactor)}</span>
                          </span>
                          </div>
                        </div>
                        
                      </div>
                    );
                  })}
                </div>

                {errors.quoteType && (
                  <span className="error-message-validator">
                    {t("this_field_is_required")}
                  </span>
                )}

                  <div className="col-md-12 mt-2">
                    <div className="regsiter_form_box_tandc">
                      <label className="d-flex" htmlFor="box">
                        <input
                          name={"partialamout"}
                          type="checkbox"
                          id="box"
                          style={{ marginRight: "5px" }}
                          {...register("partialQuoteAmount", {
                            required: false,

                          })}
                          onClick={partialAmntCheckboxHandler}
                        />
                      </label>
                      <a
                      data-bs-toggle="modal"
                      className="pop_terms_btn"
                      // onClick={handleTogglePartialAmntModal}
                      >
                        {t("do_u_want_to_paid_only", { percentage: quotesData?.minRegPay ?? "40%" })}
                      </a>

                    </div>
                  </div>
              </div>
            )}

            {activeStep === 3 && (
              <div className="row">
                <h5>
                  {t("total_amount")}: {currency?.symbolLeft}{" "}
                  {CurrencyConverter(formInputs.totalAmount, conversionFactor)}
                </h5>
                <div className="col-md-9 ps-0 skai">
                  <div
                    className="tab-content p-3 border mb-5 payment__tab_cnt"
                    id="nav-tabContent"
                  >
                    {(formInputs.paymentType === undefined ||
                      formInputs.paymentType === "") && (
                      <p>
                        <strong>{t("preferredPaymentPlaceholder")}</strong>
                      </p>
                    )}
                    {paymentGateways?.map((tab) => (
                      <div
                        key={tab.id}
                        className={`tab-pane fade ${
                          activeTab === tab.id ? "show active" : ""
                        }`}
                        id={`nav-${tab.id}`}
                        role="tabpanel"
                        aria-labelledby={`nav-${tab.id}-tab`}
                      >
                        {tab.slug === "free-joining" && (
                          <p>
                            <strong>
                              {t("freeJoinPlaceHolder", { type: "repurchase" })}
                            </strong>
                          </p>
                        )}
                        {tab.slug === "stripe" &&
                          stripePaymentDetails?.clientSecretKey && (
                            <Stripe
                              clientSecret={
                                stripePaymentDetails?.clientSecretKey
                              }
                              handleSubmit={submitHandler}
                              publicKey={stripePaymentDetails?.publicKey}
                            />
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {activeStep === 1 ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={close}
                >
                  {t("close")}
                </button>
                {requestQuoteStatus ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={nextHandler}
                  >
                    {t("next")}
                  </button>
                ) : (
                  <SubmitButton
                    className="btn btn-primary"
                    text={t("submit")}
                    click={handleSubmit(handleRequestquote)}
                    isSubmitting={requestQuote?.isLoading}
                  />
                )}
              </>
            ) : activeStep === 2 ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={previousHandler}
                >
                  {t("previous")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={nextHandler}
                >
                  {t("next")}
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={previousHandler}
                >
                  {t("previous")}
                </button>
                {selectedPayment != "stripe" && (
                  <SubmitButton
                    className="btn btn-primary"
                    text={t("submit")}
                    click={submitHandler}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RequestQuoteModal;
